/*
    このページはテンポラリでおいてます。本来、サーバーから送るべきアドレスが
    https://app.paild.jp/approvalRequest?token={} であるべきでしたが、
    https://www.paild.io/approvalRequest?token={} になっている箇所がありました。

    これを一時的に救済するために、app.paild.jpへのリダイレクトをするだけのページを用意します。
*/

const approvalRequest = () => {
    const original = window.location.href;
    const to = original.replace('www.paild.io', 'app.paild.jp');
    window.location.href = to;

    return null;
};

export default approvalRequest;